import {createRouter, createWebHistory} from 'vue-router';
import Home from './views/Home.vue'
import Blog from './views/Blog.vue'
import ItInfo from './views/ItInfo.vue'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import ContactThanks from './views/ContactThanks.vue'

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home
  },
  {
    path: '/:blogType/:page',
    name: 'BlogView',
    component: Blog,
    props: true
  },
  {
    path: '/:blogType/00xx/:page',
    name: 'BlogView00xx',
    component: Blog,
    props: true
  },
  {
    path: '/it-info',
    name: 'ItInfoView',
    component: ItInfo
  },
  {
    path: '/about',
    name: 'AboutView',
    component: About
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: Contact
  },
  {
    path: '/contact/thanks',
    name: 'ContactThanksView',
    component: ContactThanks
  }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

export default router