<template>
  <div class="blog-page" :style="tagColorStyle">
    <div class="blog-title">
      <ul>
        <li>
          <div class="blog-title-item">
            <div class="blog-title-item-tag">
              {{tagName}}
            </div>
            <div class="blog-title-item-content">
              <time :datetime="createDatetime">{{create}}</time>
              {{title}}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div>
      <div v-html="content"></div>
    </div>
    <br>
    <div class="blog-comment">
      <h2>コメント</h2>
      <div class="blog-comment-list">
          <ul>
            <li v-for="item, index in comments" :key="index">
              <div class="blog-comment-list-item">
                <ul class="blog-comment-list-item-tag">
                  <li>
                    <div>
                    {{(index + 1).toString()}}:
                    </div>
                  </li>
                  <li>
                    <div :class="getCommenterNameClass(item.imoni)">
                      {{getCommenterName(item.name)}}
                    </div>
                  </li>
                  <li>
                    <time :datetime="getDateStr2(item.date.toDate())">{{getDateStr(item.date.toDate())}}</time>
                  </li>
                </ul>
                <div class="blog-comment-list-item-content">
                  {{item.content}}
                </div>
              </div>
            </li>
          </ul>
      </div>
      <div class="blog-comment-input">
        <h3>コメントを投稿する</h3>
        <input type="text" v-model="comment.name" placeholder="名無し">
        <textarea cols="50" rows="5" v-model="comment.content" placeholder="コメントを入力してください"></textarea>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        <button @click="sendData" :disabled="!isSendButtonEnable">投稿</button>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
import hljs from 'highlight.js'
import '/public/static/css/github-dark.css'

import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore/lite';

export default {
  name: 'BlogPage',
  props: {
    blogType: {
      type: String,
      default: 'blog'
    },
    page: {
      type: Number,
      default: 0
    },
    colName: {
      type: String,
      default: 'blog'
    }
  },
  data: function () {
    return {
      tagColor: 'blue',
      title: '',
      tagName: 'imoni',
      create: '',
      createDatetime: '',
      content: '',
      comments: [],
      comment: {
        name: '',
        content: ''
      }
    }
  },
  created: async function () {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + process.env.VUE_APP_RECAPT_KEY)
    document.head.appendChild(recaptchaScript)
    this.initMarked()
    await this.loadData()
  },
  watch: {
    page: async function () {
      await this.loadData()
    }
  },
  computed: {
    isSendButtonEnable() {
      if(this.comment.content == ''){
        return false
      }
      return true
    },
    tagColorStyle: function(){
      return '--main-color: var(--color-' + this.tagColor + ')'
    }
  },
  methods: {
    initMarked: function() {
      marked.setOptions({
        langPrefix: 'hljs language-',
        highlight: function(code, lang) {
          return hljs.highlightAuto(code, [lang]).value
        },
        pedantic: false,    // Markdown.pl準拠を無効
        gfm: true,          // GitHub Flavored Markdown を有効
        breaks: true        // 改行文字での改行を有効(半角スペース2つは不要)
      })
    },
    loadData: async function() {
      this.title = ''
      this.create = ''
      this.createDatetime = ''
      this.content = ''
      let db = getFirestore()
      let colRef = collection(db, this.colName)
      const q = query(colRef, where('id', '==', this.page))
      let docSnapshot = await getDocs(q)
      if(docSnapshot.docs.length == 0){
        return
      }
      let docData = docSnapshot.docs[0].data()
      this.title = docData.title
      this.$emit("updateTitle", this.title);
      this.content = marked(docData.content)
      this.create = this.getDateStr(docData.create.toDate())
      this.createDatetime = this.getDateStr2(docData.create.toDate())
      this.comments = docData.comments

      let colRefT = collection(db, 'blog-tags')
      const qT = query(colRefT, where('id', '==', docData.tag_id))
      let docSnapshotT = await getDocs(qT)
      if(docSnapshotT.docs.length == 0){
        return
      }
      let docDataT = docSnapshotT.docs[0].data()
      this.tagName = docDataT.name
      this.tagColor = docDataT.color
    },
    loadCommentData: async function() {
      let db = getFirestore()
      let colRef = collection(db, this.colName)
      const q = query(colRef, where('id', '==', this.page))
      let docSnapshot = await getDocs(q)
      if(docSnapshot.docs.length == 0){
        return
      }
      let docData = docSnapshot.docs[0].data()
      this.comments = docData.comments
    },
    sendData: function(){
      if(process.env.VUE_APP_MODE == 'debug'){
        this.sendDataToApi('')
        return
      }
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(process.env.VUE_APP_RECAPT_KEY).then(function(token) {
          this.sendDataToApi(token)
        }.bind(this))
      }.bind(this))
    },
    sendDataToApi: function(token){
      this.axios.post(process.env.VUE_APP_API + '/api/blog_comment',{
        params: {
          data: {
            blog_type: this.blogType.replace('dev-', ''),
            page: this.page,
            name: this.comment.name,
            content: this.comment.content,
            recaptcha_token: token
          }
        }
      }).then(() => {
        this.loadCommentData()
        this.comment.name = ''
        this.comment.content = ''
      }).catch((e) => {
        console.log(e);
      });
    },
    getDateStr: function(date) {
      return 'YYYY年MM月DD日 hh:mm:ss'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
                .replace(/hh/g, this.zFill(date.getHours()))
                .replace(/mm/g, this.zFill(date.getMinutes()))
                .replace(/ss/g, this.zFill(date.getSeconds()))
    },
    getDateStr2: function(date) {
      return 'YYYY-MM-DDThh:mm:ss'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
                .replace(/hh/g, this.zFill(date.getHours()))
                .replace(/mm/g, this.zFill(date.getMinutes()))
                .replace(/ss/g, this.zFill(date.getSeconds()))
    },
    getCommenterName: function(name) {
      if(name == '' || name == null){
        return '名無し'
      }
      return name
    },
    getCommenterNameClass: function(mode) {
      if(mode === 1){
        return 'blog-comment-list-item-tag-imoni'
      }
      return ''
    },
    zFill: function(value) {
      return ('0' + value).slice(-2)
    }
  }
}
</script>

<style scoped>
.blog-page ::v-deep(h1){
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  background: var(--main-color);
  color: white;
}
.blog-page ::v-deep(h2){
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  color: var(--main-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
}
.blog-page ::v-deep(h3){
  font-size: large;
  color: var(--main-color);
  line-height: 100%;
}
.blog-page ::v-deep(p){
  font-size: medium;
  line-height: 30px;
}
.blog-page ::v-deep(img){
    max-width: calc(100% - 10px);
    margin: 10px 5px;
}
.blog-page ::v-deep(a:link){
  text-decoration:underline;
  color: var(--main-color);
}
.blog-page ::v-deep(a:visited){
  text-decoration:underline;
  color: var(--main-color);
}
.blog-page ::v-deep(a:hover){
  color: var(--main-color);
}

.blog-page ::v-deep(code){
  font-size: 16px;
  line-height: 20px;
}
.blog-page ::v-deep(code:not(.hljs)) {
  margin: 0px 2px;
  padding: 2px 4px;
  background-color: black;
  color: white;
  border-radius: 4px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  /* font-weight: bold; */
}
.blog-page ::v-deep(code) {
  border-radius: 4px;
}

.blog-title ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.blog-title li{
    margin-bottom: 5px;
    padding: 0;
    border: solid 2px var(--main-color);
}
.blog-title a{
    text-decoration: none;
}
.blog-title-item{
    display: table;
}
.blog-title-item-tag{
    display: table-cell;
    width: 100px;
    min-width: 100px;
    background-color: var(--main-color);
    color: white;
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.blog-title-item-content{
    display: table-cell;
    width: 700px;
    max-width: 700px;
    padding-left: 10px;
    font-size: larger;
    font-weight: bold;
    color: var(--main-color);
}
.blog-title-item-content time{
    display: block;
    font-size: small;
    font-weight: normal;
}


.blog-comment{
    padding: 10px;
    border: solid 2px var(--main-color);
}
.blog-comment-input button{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    background-color: var(--main-color);
}
.blog-comment-input input{
    width: 100%;
}
.blog-comment-input textarea{
    width: 100%;
}
.blog-comment-list ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.blog-comment-list li{
    margin-bottom: 5px;
    border-bottom: solid 1px var(--main-color);
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.blog-comment-list-item{
    padding-top: 5px;
    padding-bottom: 5px;
}
.blog-comment-list-item-tag div{
    margin-right: 5px;
    margin-bottom: 0px;
}
.blog-comment-list-item-tag li{
    padding: 0px;
    display: table-cell;
    color: var(--main-color);
    border: none;
    margin-right: 5px;
    margin-bottom: 0px;
}
.blog-comment-list-item-tag time{
    display: block;
    font-size: small;
    font-weight: normal;
    color: black;
    margin-top: 4px;
}
.blog-comment-list-item-content{
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
.blog-comment-list-item-tag-imoni{
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    background: var(--main-color);
}
</style>
