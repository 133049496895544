<template>
  <div>
    <h1>お問い合わせ完了</h1>
    <h2>送信ありがとうございます</h2>
    <div class="clsMain_Content">
        <p>この度はお問い合わせメールをお送りいただき、ありがとうございます。</p>
        <br>
        <p>後ほど、ご連絡をさせていただきます。</p>
        <p>今しばらくお待ちくださいますよう、よろしくお願い申し上げます。</p>
        <br>
        <p>なお、しばらくたっても返信、返答がない場合は、ご入力いただいたメールアドレスに誤りがある場合がございます。</p>
        <br>
        <p>その際は、お手数ですが再度送信いただきますよう、よろしくお願い申し上げます。</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactThanksView',
  components: {},
  created() {
    document.title = 'お問い合わせありがとうございます | imoniの開発Blog'
  },
}
</script>

<style scoped>
button {
  margin-bottom: 5px;
  width: 100%;
}
h1 {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  background: var(--main-color);
  color: white;
  text-align: center;
}
h2 {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  color: var(--main-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
}
h3 {
  font-size: large;
  color: var(--main-color);
  line-height: 100%;
}
p {
  font-size: medium;
  line-height: 30px;
}
input {
  width: 100%;
}
textarea {
  width: 100%;
}
</style>
