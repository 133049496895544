<template>
  <div>
    <div class="list-block">
      <h1>Blog</h1>
      <BlogList blog-type="blog" :colName="blogColName"/>
    </div>
    <div class="list-block">
      <h1>Tips</h1>
      <BlogList blog-type="tips" :colName="tipsColName"/>
    </div>
  </div>
</template>

<script>
import BlogList from '../components/BlogList'

export default {
  name: 'HomeView',
  components: {
    BlogList
  },
  data: function () {
    return {
      blogColName: process.env.VUE_APP_BLOG_PAGES,
      tipsColName: process.env.VUE_APP_TIPS_PAGES
    }
  },
  created: async function () {
    document.title = 'imoniの開発Blog'
  },
}
</script>

<style scoped>

h1{
    margin-top: 0px;
    margin-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: medium;
    text-align: center;
    background: var(--main-color);
    color: white;
}

a {
  text-decoration: none;
}

.list-block {
  display: inline-block;
  width: 48%;
  margin-right: 1%;
  margin-left: 1%;
}
</style>
