<template>
  <div>
    <h1>imoniの開発Blog について</h1>
    <h3>プライベートで行っている<br><br>
    Web開発や組込み開発の内容<br><br>
    他、気になることなどを<br><br>
    投稿していきます</h3>
    <br>
    <h1>プロフィール</h1>
    <div v-for="about in abouts" :key="about.id" v-html="about.content"></div>
  </div>
</template>

<script>
import { marked } from 'marked'
import { getFirestore, collection, query, getDocs, orderBy } from 'firebase/firestore/lite';

export default {
  name: 'AboutView',
  components: {},
  data: function () {
    return {
      abouts: []
    }
  },
  created: async function () {
    document.title = 'imoniの開発Blogについて | imoniの開発Blog'
    this.initMarked()
    await this.loadData()
  },
  methods: {
    initMarked: function() {
      marked.setOptions({
        pedantic: false,    // Markdown.pl準拠を無効
        gfm: true,          // GitHub Flavored Markdown を有効
        breaks: true        // 改行文字での改行を有効(半角スペース2つは不要)
      })
    },
    loadData: async function() {
      this.content = ''
      let db = getFirestore()
      let colRef = collection(db, 'develop-about')
      const q = query(colRef, orderBy('id', 'desc'))
      let docSnapshot = await getDocs(q)
      if(docSnapshot.docs.length == 0){
        return
      }
      docSnapshot.docs.forEach(function(doc) {
        let docData = doc.data()
        this.abouts.push({
          id: docData.id,
          content: marked(docData.content)
        })
      }.bind(this))
    }
  }
}
</script>

<style scoped>
::v-deep(h1) {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  background: var(--main-color);
  color: white;
  text-align: center;
}
::v-deep(h2) {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  color: var(--main-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
  text-align: center;
}
::v-deep(h3) {
  font-size: large;
  color: var(--main-color);
  text-align: center;
  line-height: 15px;
}
::v-deep(p) {
  font-size: medium;
  text-align: center;
  line-height: 30px;
}
::v-deep(ul) {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
::v-deep(li) {
  font-size: medium;
  text-align: center;
  line-height: 30px;
}
</style>
