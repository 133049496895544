<template>
  <div class="magazine-list">
    <ul>
      <li v-for="item in list" :key="item.id" :style="getTagColor(item.name)">
        <div class="magazine-list-item">
          <div class="magazine-list-item-content magazine-list-item-content-half">
            <div class="magazine-list-item-tag magazine-list-item-tag-half">
              {{item.name}} {{item.number}}
            </div>
            <div class="magazine-list-item-content-subtitle">- Release -</div>
            <time :datetime="item.releaseDatetime">{{item.releaseDate}}</time>
            <div class="magazine-list-item-content-subtitle">- Outline -</div>
            <div v-for="outline in item.topOutlines" :key="outline">
              {{outline}}
            </div>
            <div class="magazine-list-item-content-subtitle">- Detail -</div>
            <div class="magazine-list-link">
              <a :href="item.url" target="_blank">Official Page</a>
            </div>
            <div class="magazine-list-item-content-subtitle">- Store -</div>
            <div class="magazine-list-item-content-link-btn"
                v-for="storeLink in item.storeLinks" :key="storeLink.name">
              <a :href="storeLink.url"
                class="magazine-list-item-hover" :style="getTagColor(item.name)"
                target="_blank">
                {{storeLink.name}}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getFirestore, collection, query, getDocs, orderBy, limit } from 'firebase/firestore/lite';

export default {
  name: 'MagazineList',
  props: {
  },
  data: function () {
    return {
      list: []
    }
  },
  created: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function() {
      let db = getFirestore()
      let colRef = collection(db, 'magazine-release-info')
      const q = query(colRef, orderBy('release_date', 'desc'), limit(10))
      let docSnapshot = await getDocs(q)
      docSnapshot.docs.forEach(function(doc) {
        let docData = doc.data()
        let data = {
          name: docData.name,
          number: docData.number,
          url: docData.url,
          releaseDate: this.getDateStr(docData.release_date.toDate()),
          releaseDatetime: this.getDateStr2(docData.release_date.toDate()),
          topOutlines: docData.top_outlines,
          storeLinks: docData.store_links
        }
        this.list.push(data)
      }.bind(this))
    },
    getDateStr: function(date) {
      return 'YYYY年MM月DD日'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
    },
    getDateStr2: function(date) {
      return 'YYYY-MM-DD'
                .replace(/YYYY/g, date.getFullYear())
                .replace(/MM/g, this.zFill(1 + date.getMonth()))
                .replace(/DD/g, this.zFill(date.getDate()))
    },
    getTagColor: function(magazineName) {
      let color = 'blue'
      switch(magazineName){
        case '日経Linux': color = 'black'; break
        case '日経ソフトウエア': color = 'green'; break
        case 'トランジスタ技術': color = 'red'; break
        case 'Interface': color = 'orange'; break
        case 'Software Design': color = 'lightblue'; break
        case 'WEB+DB PRESS': color = 'purple'; break
      }
      return '--main-color: var(--color-' + color + ')'
    },
    zFill: function(value) {
      return ('0' + value).slice(-2)
    }
  }
}
</script>

<style scoped>
.magazine-list ul{
  margin: 0;
  padding: 0;
  padding-right: 5px;
  list-style: none;
}
.magazine-list li{
  margin-bottom: 5px;
  padding: 0;
  border: solid 2px var(--main-color);
}
.magazine-list a{
  text-decoration: none;
}

.magazine-list-item{
   display: table;
}
.magazine-list-item-tag{
    display: table-cell;
    width: 100px;
    min-width: 100px;
    background-color: var(--main-color);
    color: white;
    font-size: x-large;
    font-weight: bold; 
    text-align: center;
    vertical-align: middle;
}
.magazine-list-item-tag-half{
    width: 800px;
    max-width: 800px;
    font-size: large;
}
.magazine-list-item-content{
    display: table-cell;
    width: 700px;
    max-width: 700px;
    padding-left: 10px;
    font-size: medium;
    font-weight: normal; 
    color: var(--main-color);
}
.magazine-list-item-content-subtitle{
    font-weight: bold; 
}
.magazine-list-item-content-half{
    width: 800px;
    max-width: 800px;
    padding: 0px;
    text-align: center;
}
.magazine-list-item-content time{
    display: block;
    font-size: medium;
    font-weight: normal; 
}
.magazine-list-item-content-link-btn{
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.magazine-list-item-content-link-btn a{
  display: block;
  float: left;
  margin: 3px;
  padding: 3px;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  background-color: var(--main-color);
  color: white;
}
.magazine-list-item-hover:hover{
  opacity: 0.6;
}

.magazine-list-link ::v-deep(a:link){
  text-decoration:underline;
  color: var(--main-color);
}
.magazine-list-link ::v-deep(a:visited){
  text-decoration:underline;
  color: var(--main-color);
}
.magazine-list-link ::v-deep(a:hover){
  color: var(--main-color);
}

button.magazine-list-more{
    margin-bottom: 5px;
    width: 100%;
}
</style>
