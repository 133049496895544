<template>
  <div>
    <h1>お問い合わせ</h1>
    <div class="clsMain_Content">
      <h3>お名前</h3>
      <input type="text" v-model="name">
      <h3>アドレス</h3>
      <input type="text" v-model="address">
      <h3>件名</h3>
      <input type="text" v-model="title">
      <h3>お問い合わせ内容</h3>
      <textarea cols="50" rows="5"  v-model="content"/>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      <br>
      <button @click="sendData" :disabled="!isSendButtonEnable">送信</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactView',
  components: {},
  data: function () {
    return {
      name: '',
      address: '',
      title: '',
      content: '',
      canSendData: false
    }
  },
  created() {
    document.title = 'お問い合わせ | imoniの開発Blog'
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + process.env.VUE_APP_RECAPT_KEY)
    document.head.appendChild(recaptchaScript)
  },
  computed: {
    isSendButtonEnable() {
      if(this.name == ''){
        return false
      }
      if(this.address == ''){
        return false
      }
      if(this.title == ''){
        return false
      }
      if(this.content == ''){
        return false
      }
      return true
    }
  },
  methods: {
    sendData: function(){
      if(this.address.indexOf('@') < 0){
        alert('@を含めたメールアドレスを入力してください。')
        return
      }
      if(process.env.VUE_APP_MODE == 'debug'){
        this.sendDataToApi('')
        return
      }
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(process.env.VUE_APP_RECAPT_KEY).then(function(token) {
          this.sendDataToApi(token)
        }.bind(this))
      }.bind(this))
    },
    sendDataToApi: function(token){
      this.axios.post(process.env.VUE_APP_API + '/api/contact',{
        params: {
          data: {
            name: this.name,
            address: this.address,
            title: this.title,
            content: this.content,
            recaptcha_token: token
          }
        }
      }).then((response) => {
        console.log(response.data);
        let res = response.data
        if(res.status != 'ok'){
          alert(res.message)
          return
        }
        this.$router.push('/contact/thanks')
      }).catch((e) => {
        console.log(e);
      })
    }
  }
}
</script>

<style scoped>
button {
  margin-bottom: 5px;
  width: 100%;
}
h1 {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  background: var(--main-color);
  color: white;
  text-align: center;
}
h2 {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  color: var(--main-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
}
h3 {
  font-size: large;
  color: var(--main-color);
  line-height: 100%;
}
p {
  font-size: medium;
  line-height: 30px;
}
a {
  color: var(--main-color);
}
input {
  width: 100%;
}
textarea {
  width: 100%;
}
</style>
