import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  projectId: process.env.VUE_APP_GCP_PROJECT_ID,
}
initializeApp(firebaseConfig)

// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)
app.use(VueAxios, axios)

app.mount('#app')

// Now the app has started!