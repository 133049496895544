<template>
  <div>
    <h2>IT Magazine - Release News</h2>
    <div class="list-block">
      <MagazineList/>
    </div>
  </div>
</template>

<script>
import MagazineList from '../components/MagazineList'

export default {
  name: 'ItInfoView',
  components: {
    MagazineList
  },
  data: function () {
    return {}
  },
  created: async function () {
    document.title = 'IT Info | imoniの開発Blog'
  },
}
</script>

<style scoped>

h1{
    margin-top: 0px;
    margin-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: medium;
    text-align: center;
    background: var(--main-color);
    color: white;
}
::v-deep(h2) {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-large;
  color: var(--main-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
  text-align: center;
}

a {
  text-decoration: none;
}

.list-block {
  display: inline-block;
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
}
</style>
